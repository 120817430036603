import React from 'react';

// Components
// import TopBar from './TopBar';
// import SideBar from './SideBar';

// Interfaces
import { LayoutProps } from './Layout.interface';

// CSS
import './Layout.css';

const Layout: React.FC<LayoutProps> = ({ content }) => {
  return (
    <>
      {/* Top Bar */}
      {/* <TopBar /> */}

      {/* Side Bar */}
      {/* <SideBar /> */}

      {/* Content Area */}
      <div className="main-content-area">{content}</div>
    </>
  );
};

export default Layout;
