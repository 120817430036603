const ROLE = {
  User: 'User',
  SUPER_ADMIN: 'SuperAdmin'
};

const ALL_ROLES = [ROLE.User, ROLE.SUPER_ADMIN];

const VOICE_COMMANDS = {
  PLAY_BACKGROUND_MUSIC: [
    'play background music',
    'unmute background music',
    'unmute music',
    'play music',
    'start background music',
    'start music'
  ],
  PAUSE_BACKGROUND_MUSIC: [
    'pause background music',
    'mute background music',
    'mute music',
    'pause music',
    'stop background music',
    'stop music'
  ],
  PLAY_VIDEO: ['play video', 'start video'],
  PAUSE_VIDEO: ['pause video', 'stop video'],
  INCREASE_VIDEO_VOLUME: ['increase video volume'],
  DECREASE_VIDEO_VOLUME: ['decrease video volume'],
  MUTE_VIDEO_VOLUME: ['mute video volume', 'stop video volume'],
  UNMUTE_VIDEO_VOLUME: ['unmute video volume', 'start video volume'],
  PLAY_GAME: ['play game', 'play spin and wheel game', 'play shaheen game'],
  EXIT_GAME: ['exit game'],
  SPIN_AGAIN: [
    'play game',
    'play again',
    'play game again',
    'play shaheen game',
    'play shaheen game again',
    'spin again',
    'show me shaheen game'
  ],
  CLOSE_GAME_MODAL: ['close', 'close modal', 'close popup', 'close reward'],
  RELOAD_ASSISTANT: ['reload assistant', 'refresh assistant']
};

const SHAHEEN_SIMILAR_WORDS = [
  'shaheen',
  'shine',
  'shahin',
  'shahil',
  'sahin',
  'sahil',
  'shan',
  'shahi',
  'sahi',
  'sign'
];

const START_ASSISTANT_PHRASES = ['hi shaheen', 'hey shaheen', 'hi assistant', 'hey assistant'];

const START_ASSISTANT_SIMILAR_PHRASES = [
  ...SHAHEEN_SIMILAR_WORDS,
  'hi, shaheen',
  'hi, shine',
  'hi, shahin',
  'hi, shahil',
  'hi, sahin',
  'hi, sahil',
  'hi, shan',
  'hi, shahi',
  'hi, sahi',
  'hi, sign',
  'hi, shaheen,',
  'hi, shine,',
  'hi, shahin,',
  'hi, shahil,',
  'hi, sahin,',
  'hi, sahil,',
  'hi, shan,',
  'hi, shahi,',
  'hi, sahi,',
  'hi, sign,',
  'hi shine',
  'hi shahin',
  'hi shahil',
  'hi sahin',
  'hi sahil',
  'hi shan',
  'hi shahi',
  'hi sahi',
  'hi sign',
  'hi shine,',
  'hi shahin,',
  'hi shahil,',
  'hi sahin,',
  'hi sahil,',
  'hi shan,',
  'hi shahi,',
  'hi sahi,',
  'hi sign,',
  'hey shine',
  'hey shahin',
  'hey shahil',
  'hey sahin',
  'hey sahil',
  'hey shan',
  'hey shahi',
  'hey sahi',
  'hey sign',
  'hey shine,',
  'hey shahin,',
  'hey shahil,',
  'hey sahin,',
  'hey sahil,',
  'hey shan,',
  'hey shahi,',
  'hey sahi,',
  'hey sign,',
  'hey, shaheen',
  'hey, shine',
  'hey, shahin',
  'hey, shahil',
  'hey, sahin',
  'hey, sahil',
  'hey, shan',
  'hey, shahi',
  'hey, sahi',
  'hey, sign',
  'hey, shaheen,',
  'hey, shine,',
  'hey, shahin,',
  'hey, shahil,',
  'hey, sahin,',
  'hey, sahil,',
  'hey, shan,',
  'hey, shahi,',
  'hey, sahi,',
  'hey, sign,'
];

const RELOAD_ASSISTANT_PHRASES = ['no', 'nope'];

const MESSAGES = {
  HELP_QUESTION: 'Do you have any other questions for me or need any help?',
  HELP_TEXT: `Hey there! I'm Shaheen, your AI assistant. How can I assist you today?`,
  PLAY_BACKGROUND_MUSIC: 'Okay, playing background music!',
  BACKGROUND_MUSIC_ALREADY_PLAYING: 'Background music is already playing!',
  BACKGROUND_MUSIC_ALREADY_PAUSED: 'Background music is already paused!',
  PAUSE_BACKGROUND_MUSIC: 'Okay, pausing background music!',
  PLAY_GAME: `Let's play the Shaheen Game!`,
  PLAY_GAME_AGAIN: `Let's play the Shaheen Game again!`,
  CLOSE_GAME_MODAL: 'Closing game reward modal!',
  USERNAME_PASSWORD_REQUIRED:
    'Username and password must be provided or set in environment variables.'
};

const VIDEO_VOLUME_STATUS = {
  MUTE: 0,
  UNMUTE: 1,
  INCREASE_VOLUME: 2,
  DECREASE_VOLUME: 3
};

const REGEX = {
  URL: /(https?:\/\/[^\s]+)/g,
  VIDEO_FORMAT: /\.mp4$/i,
  IMAGE_FORMAT: /\.(jpe?g|png|svg)$/i
};

const GAME_SEGMENTS = [
  'You win a Mug!',
  'Try our \n chocolate!',
  'Good luck \n next time',
  'You win a \n Bookmark!',
  'Try our \n perfume!',
  'Sustainable \n Pencil!'
];

const GAME_SEGMENTS_COLORS = ['#236440', '#205d39', '#205136', '#236440', '#205d39', '#205136'];

const SHAHEEN_AUDIO = process.env.REACT_APP_SHAHEEN_AUDIO;

export {
  ROLE,
  ALL_ROLES,
  VOICE_COMMANDS,
  SHAHEEN_SIMILAR_WORDS,
  START_ASSISTANT_PHRASES,
  START_ASSISTANT_SIMILAR_PHRASES,
  RELOAD_ASSISTANT_PHRASES,
  MESSAGES,
  VIDEO_VOLUME_STATUS,
  REGEX,
  GAME_SEGMENTS,
  GAME_SEGMENTS_COLORS,
  SHAHEEN_AUDIO
};
