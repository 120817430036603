// Pages
import Dashboard from '../pages/Dashboard';
import TypeAssistant from '../pages/TypeAssistant';
import PlayGame from '../pages/PlayGame/PlayGame';

// Routes
import { ROUTE_LIST } from './routes';

// Constants
import { ALL_ROLES } from './common';

// Interfaces
import { privateRoutesType } from '../interfaces/global_interfaces';

export const PRIVATE_ROUTES: privateRoutesType[] = [
  {
    path: ROUTE_LIST.DASHBOARD,
    component: Dashboard,
    role: ALL_ROLES
  },
  {
    path: ROUTE_LIST.TYPE_ASSITANT,
    component: TypeAssistant,
    role: ALL_ROLES
  },
  {
    path: ROUTE_LIST.PLAY_GAME,
    component: PlayGame,
    role: ALL_ROLES
  }
];
