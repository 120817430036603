import React from 'react';

// Interfaces
import { ImageListProps } from './ImageList.interface';

const ImageList: React.FC<ImageListProps> = ({ imageUrls }) => {
  const getColSize = (imageList: string[]) => {
    const length = imageList.length;
    if (length === 3) return 'three-col';
    if (length === 4) return 'four-col';
    if (length === 5) return 'five-col';
    if (length >= 6 && length <= 15) return 'six-col';
    return '';
  };

  return (
    <ul className={getColSize(imageUrls)}>
      {imageUrls.map((url, index) => (
        <li key={index}>
          <div className="img-wrapper">
            <img src={url} alt={`image-${index}`} />
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ImageList;
