import { configureStore } from '@reduxjs/toolkit';

import apiStatusReducer from './features/ApiStatus/apiStatusSlice';

export const store = configureStore({
  reducer: {
    apiStatus: apiStatusReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
