import { Buffer } from 'buffer';
import { MESSAGES, REGEX } from '../constants/common';

const containsVideo = (urls: string) => {
  for (const url of urls) {
    if (REGEX.VIDEO_FORMAT.test(url)) {
      return true;
    }
    if (!REGEX.IMAGE_FORMAT.test(url)) {
      return true;
    }
  }
  return false;
};

const getVideoLabel = (url: string) => {
  const decodedUrl = decodeURIComponent(url);
  const filename = decodedUrl.substring(decodedUrl.lastIndexOf('/') + 1);
  let name = filename.substring(0, filename.lastIndexOf('.'));
  // Replace %20 with spaces
  name = name.replace(/%20/g, ' ');
  // Replace + with spaces
  name = name.replace(/\+/g, ' ');
  return name;
};

const getAuthToken = (
  username = process.env.REACT_APP_USERNAME,
  password = process.env.REACT_APP_PASSWORD
) => {
  if (!username || !password) {
    throw new Error(MESSAGES.USERNAME_PASSWORD_REQUIRED);
  }
  const auth = 'Basic ' + Buffer.from(username + ':' + password).toString('base64');
  return auth;
};

const checkSimilarWords = (question: string, similarWords: string[]) => {
  const lowerCaseQuestion = question.toLowerCase();
  for (let i = 0; i < similarWords.length; i++) {
    const similarWord = similarWords[i];
    if (lowerCaseQuestion.includes(similarWord)) {
      return true;
    }
  }
  return false;
};

export { containsVideo, getVideoLabel, getAuthToken, checkSimilarWords };
