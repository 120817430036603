import axios, { InternalAxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import {
  apiPending,
  apiStatusClear,
  apiFail,
  apiSuccess
} from '../redux/features/ApiStatus/apiStatusSlice';

// Redux-Store
import { store } from '../redux/store';

// Constants
import { API_ERROR_MESSAGES, RESPONSE_STATUS_CODES } from '../constants/general';
import { API_ROUTES } from '../constants/apiRoutes';

// Interfaces
import { ApiErrorMessages } from '../interfaces/global_interfaces';

// Domain  config
import domainConfig from './domainConfig';

// Auth Token
import { getAuthToken } from './helper';

axios.defaults.baseURL = domainConfig.BACK_END_URL;

axios.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const url = config.url?.split('?')[0];
    const token = getAuthToken();
    if (url) {
      store.dispatch(apiStatusClear());
      store.dispatch(apiPending());
    }
    // If the URL is not for login, then set the access token in the header
    if (config.url !== API_ROUTES.LOGIN) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error: AxiosError) => {
    store.dispatch(apiFail(error.response?.data));
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    store.dispatch(apiSuccess());
    return response;
  },
  (error) => {
    if (error && error.response && error.response.status && error.response.data) {
      const { status, data } = error.response;
      const apiErrorMessage = data.message;
      store.dispatch(apiFail(apiErrorMessage.message));
      if (apiErrorMessage) {
        toast.error(apiErrorMessage as string);
      } else if (API_ERROR_MESSAGES[status as keyof ApiErrorMessages]) {
        toast.error(API_ERROR_MESSAGES[status as keyof ApiErrorMessages]);
      } else {
        toast.error(API_ERROR_MESSAGES.default);
      }
    } else {
      store.dispatch(apiFail(error?.message));
      toast.error(error?.message);
    }
    return Promise.reject(error);
  }
);

export default axios;
