// Assign the empty path string if the prefix path value is undefined.
const PREFIX = process.env.REACT_APP_PREFIX_ROUTE ? `/${process.env.REACT_APP_PREFIX_ROUTE}` : '';

// Public routes
export const ROUTE_LIST = {
  LOGIN: `${PREFIX}/login`,
  LOGOUT: `${PREFIX}/logout`,
  DASHBOARD: `${PREFIX}/`,
  TYPE_ASSITANT: `${PREFIX}/type-assistant`,
  PLAY_GAME: `${PREFIX}/play-game`
};
