import React from 'react';
import IconButton from '@mui/material/IconButton';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import { MicrophoneToggleButtonProps } from './MicrophoneToggleButton.interface';

import './MicrophoneToggleButton.css';

const MicrophoneToggleButton: React.FC<MicrophoneToggleButtonProps> = ({ listening }) => {
  return (
    <div className="mic-wrap">
      {listening && <div className="wave-effect" />}
      <IconButton className="mic-icon">{listening ? <MicIcon /> : <MicOffIcon />}</IconButton>
    </div>
  );
};

export default MicrophoneToggleButton;
