import React, { useState, useEffect } from 'react';
import { TypeWriterProps } from './TypeWriter.interface';

const Typewriter: React.FC<TypeWriterProps> = ({ text, speed }) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setCurrentIndex(0);
    setDisplayText('');
  }, [text]);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timeoutId = setTimeout(() => {
        setDisplayText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, speed); // Adjust the typing speed (in milliseconds)

      return () => clearTimeout(timeoutId);
    }
  }, [currentIndex, text, speed]);

  return (
    <p className="answer">
      {displayText.split('\n').map((line) => (
        <>
          {line}
          <br />
        </>
      ))}
    </p>
  );
};

export default Typewriter;
