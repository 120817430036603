import React from 'react';
import ReactDOM from 'react-dom/client';

// CSS
import './index.css';

// Redux-Provider
import { Provider } from 'react-redux';

// Redux-Store
import { store } from './redux/store';

// Components
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
