import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { Modal, Box, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

//css
import './PlayGame.css';

// Components
import WheelComponent from '../../components/WheelComponent';

// Constants
import {
  GAME_SEGMENTS,
  GAME_SEGMENTS_COLORS,
  MESSAGES,
  SHAHEEN_AUDIO,
  VOICE_COMMANDS
} from '../../constants/common';
import { ROUTE_LIST } from '../../constants/routes';

const PlayGame: React.FC = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#1976d2'
      },
      secondary: {
        main: '#f50057'
      }
    }
  });
  const navigate = useNavigate();
  const [isSpinning, setIsSpinning] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [winningPrize, setWinningPrize] = useState('');
  const audioRef = useRef(new Audio(SHAHEEN_AUDIO));

  const commands = [
    {
      command: VOICE_COMMANDS.EXIT_GAME,
      callback: () => {
        navigate(ROUTE_LIST.DASHBOARD);
      },
      isFuzzyMatch: true
    },
    {
      command: VOICE_COMMANDS.SPIN_AGAIN,
      callback: () => handlePlayGameAgain(),
      isFuzzyMatch: true
    },
    {
      command: VOICE_COMMANDS.CLOSE_GAME_MODAL,
      callback: () => handleGameCloseModal(),
      isFuzzyMatch: true
    },
    {
      command: VOICE_COMMANDS.PLAY_BACKGROUND_MUSIC,
      callback: () => handleBackgroundAudio(true),
      isFuzzyMatch: true
    },
    {
      command: VOICE_COMMANDS.PAUSE_BACKGROUND_MUSIC,
      callback: () => handleBackgroundAudio(false),
      isFuzzyMatch: true
    }
  ];
  useSpeechRecognition({ commands });

  useEffect(() => {
    window.speechSynthesis.cancel();
    SpeechRecognition.startListening({ continuous: true });
    // Play background audio
    const audio = audioRef.current;
    audio.loop = true;
    audio.volume = 0.1;
    audio.play().catch((error) => {
      console.error('Failed to play background music:', error);
    });
    return () => {
      // Cleanup on component unmount
      window.speechSynthesis.cancel();
      // Pause background audio
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    };
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleGameCloseModal = () => {
    if (isModalOpen) {
      speakText(MESSAGES.CLOSE_GAME_MODAL);
      handleCloseModal();
    }
  };

  const handlePlayGameAgain = () => {
    if (!isSpinning) {
      setIsModalOpen(false);
      speakText(MESSAGES.PLAY_GAME_AGAIN);
      setIsSpinning(true);
    }
  };

  const handleBackgroundAudio = (play: boolean) => {
    const audio = audioRef.current;
    audio.loop = true;
    audio.volume = 0.1;
    if (play) {
      audio.play().catch((error) => {
        console.error('Failed to play background music:', error);
      });
      speakText(MESSAGES.PLAY_BACKGROUND_MUSIC);
    } else {
      audio.pause();
      audio.currentTime = 0;
      speakText(MESSAGES.PAUSE_BACKGROUND_MUSIC);
    }
  };

  const onFinished = (winner: string) => {
    setWinningPrize(winner);
    setIsModalOpen(true);
    setIsSpinning(false);
    speakText(winner.replace(/\n/g, ''));
  };

  const speakText = (text: string) => {
    window.speechSynthesis.cancel();
    const utterance = new SpeechSynthesisUtterance();
    utterance.text = text;
    window.speechSynthesis.cancel();
    window.speechSynthesis.speak(utterance);
  };

  return (
    <ThemeProvider theme={theme}>
      <div id="wheelCircle">
        <WheelComponent
          spinningStatus={isSpinning}
          segments={GAME_SEGMENTS}
          segColors={GAME_SEGMENTS_COLORS}
          winningSegment=""
          onFinished={(winner) => onFinished(winner)}
          primaryColor="black"
          primaryColoraround="#ffffffb4"
          contrastColor="white"
          buttonText="Spin"
          isOnlyOnce={false}
          size={300}
          upDuration={50}
          downDuration={2000}
        />
        <Modal
          open={isModalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description">
          <Box className="reward-modal">
            <Typography variant="h5" component="h2">
              {winningPrize !== 'Good luck \n next time' ? 'Congratulations!' : 'Oops!'}
            </Typography>
            <Typography variant="body1" id="modal-description">
              {winningPrize}
            </Typography>
          </Box>
        </Modal>
      </div>
    </ThemeProvider>
  );
};

export default PlayGame;
