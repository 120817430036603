import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

// Components
import PrivateRoutes from './components/PrivateRoute';

// Constants
import { PRIVATE_ROUTES } from './constants/privateRoutes';
import { ROUTE_LIST } from './constants/routes';

// CSS
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => {
  // ToDO: Dynamic Routing based on user role
  // useEffect(() => {
  //   if (accessRole && isLoggedIn) {
  //     const accessibleRoutes = PRIVATE_ROUTES.filter((route) => {
  //       if (route.role.includes(accessRole)) return route;
  //     });
  //     setAuthRoutes(accessibleRoutes);
  //   }
  // }, [isLoggedIn, accessRole]);

  return (
    <>
      <Router>
        <Routes>
          {/****** PUBLIC ROUTES ******/}

          {/****** PRIVATE ROUTES ******/}
          <Route element={<PrivateRoutes />}>
            {PRIVATE_ROUTES.map((privateRoute, index) => {
              return (
                <Route path={privateRoute.path} Component={privateRoute.component} key={index} />
              );
            })}
            <Route path="/" element={<Navigate to={ROUTE_LIST.DASHBOARD} />} />
          </Route>
        </Routes>
      </Router>
      <ToastContainer
        autoClose={3000}
        className="toast-notification"
        closeOnClick={false}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={true}
      />
    </>
  );
};

export default App;
