import React, { ChangeEvent, useEffect, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { TextField, InputAdornment } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Mic as MicIcon } from '@mui/icons-material';

// Components
import TypeWriter from '../../components/TypeWriter';

// Constants
import { API_ROUTES } from '../../constants/apiRoutes';
import { LOADING } from '../../constants/general';

// Redux
import { useAppSelector } from '../../redux/hook';

// Utils
import api from '../../utils/api';

// CSS
import './TypeAssistant.css';

const TypeAssistant: React.FC = () => {
  const { isLoading } = useAppSelector((state) => state.apiStatus);
  const [task, setTask] = useState('');
  const [questionAsked, setQuestionAsked] = useState<string>('');
  const { transcript, listening } = useSpeechRecognition();

  useEffect(() => {
    window.speechSynthesis.cancel();
    if (listening) setQuestionAsked(transcript);
  }, [transcript]);

  const handleMicrophone = () => {
    if (listening) {
      SpeechRecognition.stopListening();
    } else SpeechRecognition.startListening();
  };

  const handleTextInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    window.speechSynthesis.cancel();
    const text = event.target.value;
    setQuestionAsked(text);
  };

  const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const fetchData = async () => {
      try {
        const response = await api.post(API_ROUTES.OPEN_AI_CHAT, {
          question: questionAsked
        });
        const text = response.data.data.answer;
        setTask(text);
        const utterance = new SpeechSynthesisUtterance(text);
        window.speechSynthesis.cancel();
        window.speechSynthesis.speak(utterance);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    if (!isLoading && questionAsked !== '') fetchData();
  };

  return (
    <div className="search-page">
      <form onSubmit={handleSearch}>
        <div className="search-box">
          <p>Microphone: {listening ? 'on' : 'off'}</p>
          <TextField
            style={{ border: '1px solid white' }}
            fullWidth
            variant="outlined"
            placeholder="Search"
            value={questionAsked}
            onChange={handleTextInput}
            InputProps={{
              style: {
                color: 'white',
                borderColor: 'white'
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleMicrophone} edge="end">
                    <MicIcon style={{ color: 'white' }} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
      </form>
      <div className="results">{isLoading ? LOADING : <TypeWriter text={task} speed={40} />}</div>
    </div>
  );
};

export default TypeAssistant;
