import { ApiErrorMessages } from '../interfaces/global_interfaces';

const TOP_BAR_HEADING = 'Interactive Display';
const LOGIN = 'Login';
const LOGOUT = 'Logout';
const SUBMIT = 'Submit';
const ACCESS_TOKEN = 'access_token';
const NO_DATA_FOUND = 'No data found';
const LOADING = 'Loading...';

const API_ERROR_MESSAGES: Record<keyof ApiErrorMessages, string> = {
  401: 'Unauthorized',
  403: 'Forbidden Error',
  404: 'Not found',
  500: 'Internal server error',
  503: 'Service unavailable',
  default: 'Something went wrong !'
};

const RESPONSE_STATUS_CODES = {
  OK: 200,
  SESSION_EXPIRED: 419,
  TOKEN_EXPIRED: 401,
  FORBIDDEN_ERROR: 403
};

export {
  TOP_BAR_HEADING,
  LOGIN,
  LOGOUT,
  SUBMIT,
  ACCESS_TOKEN,
  NO_DATA_FOUND,
  LOADING,
  API_ERROR_MESSAGES,
  RESPONSE_STATUS_CODES
};
