import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

// Components
import Layout from '../Layout';

// Constants
import { ROUTE_LIST } from '../../constants/routes';
import { ACCESS_TOKEN } from '../../constants/general';

const PrivateRoutes: React.FC = () => {
  // ToDo: Login Access token changes
  const accessToken = localStorage.getItem(ACCESS_TOKEN) || 'access_token';
  return accessToken ? <Layout content={<Outlet />} /> : <Navigate to={ROUTE_LIST.LOGIN} />;
};

export default PrivateRoutes;
